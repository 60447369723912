<section class="header">
  <div class="container">

    <img
      class="glitch"
      data-text="HAUNTED"
      src="https://res.cloudinary.com/dadqp2oia/image/upload/v1629672279/Music%20Website/Logos/ElyzaBleau-Full-Logo_jatsxo.png"
      alt="Elyza Bleau full logo"
    />
    <p class="subtitle">A Tapestry of Neon Lace</p>
  </div>
  <div class="scanlines"></div>
</section>
