<mat-card class="epk-card">
    <mat-card-header>
      <mat-card-title>Elyza's EPK</mat-card-title>
    </mat-card-header>

    <img class="logo" src="https://res.cloudinary.com/dadqp2oia/image/upload/v1629671571/Music%20Website/ElyzaBleau-PortraitWhileFilming-lowRes_hi4hen.png" alt="photo of Elyza Bleau during filming">

    <mat-card-actions>
      <a mat-button href="/epk">Press</a>
    </mat-card-actions>
  </mat-card>
