<div class="about jarallax">

    <div class="space"></div>

    <app-bio-card-header></app-bio-card-header>
    
    <app-bio-card-one></app-bio-card-one>

    <app-bio-card-two></app-bio-card-two>

    <app-bio-card-three></app-bio-card-three>

    <app-bio-card-four></app-bio-card-four>

    <app-bio-card-five></app-bio-card-five>

    <app-bio-photo-card></app-bio-photo-card>


</div>
