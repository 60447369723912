<mat-card class="video">

    <div class="video__item">
        <iframe
          src="https://www.youtube.com/embed/0Ew5iUWkFMU"
          title="frame of the music video for the song Appartition"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>

</mat-card>

<mat-card class="video">

  <div class="video__item">
      <iframe
        src="https://www.youtube.com/embed/SWKSvGXnsVo"
        title="frame of the music video for the song Pool of Grey"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>

</mat-card>

<mat-card class="video">

  <div class="video__item">
      <iframe
        src="https://www.youtube.com/embed/Xb1UQKzHwnA"
        title="frame of the music video for the song The Diary"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>

</mat-card>

<mat-card class="video">

  <div class="video__item">
      <iframe
        src="https://www.youtube.com/embed/HS3vmxF84dg"
        title="frame of the music video for the song Testify"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>

</mat-card>
