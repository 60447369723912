import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-epk-bio-card',
  templateUrl: './epk-bio-card.component.html',
  styleUrls: ['./epk-bio-card.component.scss']
})
export class BioCardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
