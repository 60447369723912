<mat-card class="photo-card">
    <mat-card-header>
      <mat-card-title>Photo Gallery</mat-card-title>
    </mat-card-header>

    <img class="logo" src="https://res.cloudinary.com/dadqp2oia/image/upload/v1629671570/Music%20Website/Apparition-cemetery-close-lowRes_r24kjk.png" alt="photo of Elyza Bleau in Most Holy Trinity Cemetery">

    <mat-card-actions>
      <a mat-button href="/photos">Snap</a>
    </mat-card-actions>
  </mat-card>
