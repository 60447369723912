export class PhotoOptions {
    img: string;
}

export const Photos = [
    {
        img: "./src/assets/img/gallery/Apparition-studio-oneWay.jpg"
    },
    {
        img: "./src/assets/img/gallery/PoolOfGrey-portrait-night.jpg"
    },
    {
        img: "./src/assets/img/gallery/PoolOfGrey-portrait-2.jpg"
    },
]