<div class="epk-bio">

    <h4>200-250 Word Bio</h4>
    <h5>Often considered “that little tune you can’t get out of your head”, Elyza Bleau’s big belty vocals and catchy riff-driven guitar will have you redefining “little tune” with her new EP, <i>Haunted</i>. Through this collection of upbeat rock tunes, Bleau takes listeners on an East coast to West coast road trip with her beachy urban brand that’s making a splash in the alt indie rock scene.</h5>

    <h5>With a blend of the classics like Tom Petty, The Pretenders and U2 to the modern vibes of Catfish and the Bottlemen and Middle Kids, <i>Haunted</i> cruises the past using a modern lens of nostalgic emotions and gives us hope that we all know better now.</h5>
        
    <h5> So come take a ride! Bring your cassette tape phone cover and your dad’s bomber jacket while you stream the night away with Elyza Bleau’s <i>Haunted</i> EP.</h5>

    <h4>100 Word Bio</h4>
    <h5>Often considered “that little tune you can’t get out of your head”, Elyza Bleau’s big belty vocals and catchy guitar will have you redefining “little tune” with her new EP, <i>Haunted</i>. She’ll take you on an east to west coast road trip with her beachy urban brand of alt indie rock. With a blend of the classics like Tom Petty, The Pretenders and U2 to the new vibes of Catfish and the Bottlemen and Middle Kids, <i>Haunted</i> cruises the past using a modern lens of nostalgic emotions and gives us hope that we all know better now. </h5>

    <h4>Twitter sized </h4>
    <h5>Looking to take a nostalgic trip with a modern lens with 90s inspired indie rock? Elyza Bleau’s first release off of her new <i>Haunted</i> EP, “Apparition” takes Bleau’s big belty vocals on a journey into how we hang onto the past but need to let go in the process.</h5>

    <h4>A few words for IG Bio</h4>
    <h5>Ready to hang on and let go of the past while rocking out to a blend of The Pretenders and U2? Listen to Elyza Bleau’s first release off of her solo EP, “Apparition”!</h5>

</div>
