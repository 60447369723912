<mat-card class="listen-card">
    <mat-card-header>
      <mat-card-title>Listen</mat-card-title>
    </mat-card-header>

    <img class="logo" src="https://res.cloudinary.com/dadqp2oia/image/upload/v1629672279/Music%20Website/Logos/Apparition-Single-Art_jk4nmb.jpg" alt="black and white photo of Lyric Art with desert and train tracks with purple and blue color">

    <mat-card-actions>
      <a mat-button href="https://ffm.to/elyzableau" target="_blank">Stream</a>
    </mat-card-actions>
  </mat-card>
  
