<section class="gallery">
  <!-- Logo -->
  <div class="download_box">
    <img class="epk-photo" src="https://res.cloudinary.com/dadqp2oia/image/upload/v1629672279/Music%20Website/Logos/ElyzaBleau-Full-Logo_jatsxo.png" alt="Elyza cutout portrait logo with initials" />
    <a
      class="dl"
      href="https://www.dropbox.com/s/gzoyhb90wzlek9j/ElyzaBleau-Full-Logo.png?dl=1"
      >Click to Download</a
    >
  </div>

  <!-- Logo without Initials -->
  <div class="download_box">
    <img
      class="epk-photo"
      src="https://res.cloudinary.com/dadqp2oia/image/upload/v1629672279/Music%20Website/Logos/ElyzaBleau-Logo-noInitials_bvrnkv.png" alt="Elyza cutout portrait logo with no initials"
    />
    <a
      class="dl"
      href="https://www.dropbox.com/s/o73wwu6qvz9zquf/ElyzaBleau-Logo-noInitials.png?dl=1"
      >Click to Download</a
    >
  </div>

  <!-- Name Logo -->
  <div class="download_box">
    <img class="epk-photo" src="https://res.cloudinary.com/dadqp2oia/image/upload/v1629672279/Music%20Website/Logos/ElyzaBleau-Name-Logo_ietm8d.png" alt="Elyza cutout name logo"/>
    <a
      class="dl"
      href="https://www.dropbox.com/s/xz6wk28kzbqe4ji/ElyzaBleau-name-logo.png?dl=1"
      >Click to Download</a
    >
  </div>
</section>
