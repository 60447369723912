<section class="home jarallax">
  <div class="space"></div>

  <app-header-title></app-header-title>

  <app-mailing-list></app-mailing-list>

  <app-listen></app-listen>

  <app-watch></app-watch>

  <app-merch-card></app-merch-card>

  <app-about-card></app-about-card>

  <app-photo-card></app-photo-card>

  <app-epk-card></app-epk-card>

  <app-contact-card></app-contact-card>
</section>
