<mat-card class="merch-card">
    <mat-card-header>
      <mat-card-title>Merch</mat-card-title>
    </mat-card-header>

    <img class="logo" src="https://res.cloudinary.com/dadqp2oia/image/upload/v1629671572/Music%20Website/desert-parch-fdl_zrc3hh.png" alt="black and white photo of Lyric Art with desert and train tracks with purple and blue color">

    <mat-card-actions>
      <a mat-button href="https://elyza-bleau.square.site/" target="_blank">Buy</a>
    </mat-card-actions>
  </mat-card>

