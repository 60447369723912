<!-- Footer -->
<footer class="footer font-small blue">

    <!-- Copyright -->
    <div class="footer-copyright text-center py-3">All images and website © 2021 Elyza Bleau:
      <a href="https://elyzableau.com/"> elyzableau.com</a>
    </div>
    <!-- Copyright -->

    <img class="logo" src="https://res.cloudinary.com/dadqp2oia/image/upload/v1629672279/Music%20Website/Logos/ElyzaBleau-Logo-noInitials_bvrnkv.png" alt="Elyza Bleau logo">
    <img class="mat-typography" src="https://res.cloudinary.com/dadqp2oia/image/upload/v1629672279/Music%20Website/Logos/ElyzaBleau-Name-Logo_ietm8d.png" alt="Elyza Bleau name logo">
  
</footer>
  <!-- Footer -->