<div class="epk-quotes">

    <h4>What the Press Are Saying</h4>

    <h5>"Apparition” is an engrossing song in every aspect; the lyrics pull you into the story, her vocals are soulful and meaningful, and the music makes you want to get up and rock out. This song is for anyone that loves music.</h5>
    <h5 class="name"><i>-Stitched Sound</i></h5>

    <h4>What Fans Are Saying</h4>

    <h5>"Elyza’s soaring, crystalline voice sends chills down my spine, and draws me in every time."</h5>
    <h5 class="name"><i>-Hayley P.</i></h5>

    <h5>"You just *have* to dance when you hear Elyza's music. It makes you want to move! And her big epic melodies won't be leaving your head any time soon either. Love it!"</h5>
    <h5 class="name"><i>-Kyle S.</i></h5>

    <h5>"When I first listened to Elyza's EP, I had to listen to it again, immediately! These songs take you on a journey via incredible music and a strong, inspiring female voice. It TOTALLY rocks!"</h5>
    <h5 class="name"><i>-Danielle B.</i></h5>

</div>
