
<section class="gallery">
    <h4>Downloadable Photos and Logos</h4>
    <div class="row">
        <div class="column">

            <!-- Photo 1-->
            <img class="epk-photo" src="https://res.cloudinary.com/dadqp2oia/image/upload/v1629671571/Music%20Website/ElyzaBleau-nightPortrait-1-lowRes_bwq0h2.png" alt="portrait of Elyza">
            <div>
                <a class="dl" href="https://www.dropbox.com/s/gg2ebjf0f4xgtyu/ElyzaBleau-nightPortrait-1.png?dl=1">Click to Download</a>
            </div>
            <div>  
                <a class="dl" href="https://www.dropbox.com/s/ri351yuy0x1kolt/ElyzaBleau-nightPortrait-1-lowRes.png?dl=1">Click for Low Res</a>
            </div>
            <!-- Photo 2 -->
            <img class="epk-photo" src="https://res.cloudinary.com/dadqp2oia/image/upload/v1629671571/Music%20Website/ElyzaBleau-fullBody-Portrait-lowRes_ighsx0.png" alt="Full body portrait of Elyza">
            <div>
                <a class="dl" href="https://www.dropbox.com/s/9exfdzk8gznu2px/ElyzaBleau-fullBody-Portrait.png?dl=1">Click to Download</a>
            </div>
            <div>
                <a class="dl" href="https://www.dropbox.com/s/2ngu1rwnmyvg8bx/ElyzaBleau-fullBody-Portrait-lowRes.png?dl=1">Click for Low Res</a>
            </div>
        </div>
           
        <div class="column">
            <!-- Photo 3 -->
            <img class="epk-photo" src="https://res.cloudinary.com/dadqp2oia/image/upload/v1629671571/Music%20Website/ElyzaBleau-guitar-cemetery-lowRes_gv7awf.png" alt="portrait of Elyza holding guitar in cemetery">
            <div>
                <a class="dl" href="https://www.dropbox.com/s/9f11lsvidu6w296/ElyzaBleau-guitar-cemetery.png?dl=1">Click to Download</a>
            </div>
            <div>
                <a class="dl" href="https://www.dropbox.com/s/t6clppb7xez343j/ElyzaBleau-guitar-cemetery-lowRes.png?dl=1">Click for Low Res</a>
            </div>
            <!-- Photo 4 -->
            <img class="epk-photo" src="https://res.cloudinary.com/dadqp2oia/image/upload/v1629671571/Music%20Website/ElyzaBleau-seatedSmile-lowRes_hkbq9d.png" alt="portrait of Elyza seated and smiling">
            <div>
                <a class="dl" href="https://www.dropbox.com/s/dcp34pp7ug3b1hj/ElyzaBleau-seatedSmile-Portrait.png?dl=1">Click to Download</a>
            </div>
            <div>
                <a class="dl" href="https://www.dropbox.com/s/0hqwlcljf00qeda/ElyzaBleau-seatedSmile-lowRes.png?dl=1">Click for Low Res</a>
            </div>
        </div>

        <div class="column three">
            <!-- Photo 5 -->
            <img class="epk-photo" src="https://res.cloudinary.com/dadqp2oia/image/upload/v1629671571/Music%20Website/ElyzaBleau-PortraitWhileFilming-lowRes_hi4hen.png" alt="portrait of Elyza while filming">
            <div>
                <a class="dl" href="https://www.dropbox.com/s/gzrv3scky0xcsfc/ElyzaBleau-PotraitWhileFilming.png?dl=1">Click to Download</a>
            </div>
            <div>
                <a class="dl" href="https://www.dropbox.com/s/3793npnp1ni4xae/ElyzaBleau-PotraitWhileFilming-lowRes.png?dl=1">Click for Low Res</a>
            </div>
            <!-- Photo 6 -->
            <img class="epk-photo" src="https://res.cloudinary.com/dadqp2oia/image/upload/v1629671571/Music%20Website/ElyzaBleau-studio-portrait-lowRes_ppnxow.png" alt="portrait of Elyza's face">
            <div>
                <a class="dl" href="https://www.dropbox.com/s/1b6qd15okbbv1og/ElyzaBleau-Portrait-Alley.png?dl=1">Click to Download</a>
            </div>
            <div>
                <a class="dl" href="https://www.dropbox.com/s/d8ul1tb8zvz5rti/ElyzaBleau-Portrait-Alley-lowRes.png?dl=1">Click for Low Res</a>
            </div>
        </div>

    </div>
    
</section>
    
    
