<section class="gallery">

    <div class="row">
        <div class="column">
          <img src="https://res.cloudinary.com/dadqp2oia/image/upload/v1629671571/Music%20Website/ElyzaBleau-fullBody-Portrait-lowRes_ighsx0.png" alt="portrait of Elyza full body">
          <img src="https://res.cloudinary.com/dadqp2oia/image/upload/v1629671570/Music%20Website/Apparition-filming-lowRes_gtajgu.png" alt="portrait of Elyza while filming">
          <img src="https://res.cloudinary.com/dadqp2oia/image/upload/v1629671571/Music%20Website/Apparition-seated-guitar-lowRes_frqzpv.png" alt="portrait of Elyza seated with guitar">
          <img src="https://res.cloudinary.com/dadqp2oia/image/upload/v1629671570/Music%20Website/Apparition-cemetery-close-lowRes_r24kjk.png" alt="portrait of Elyza in cemetery">

        </div>
        <div class="column">
          <img src="https://res.cloudinary.com/dadqp2oia/image/upload/v1629671571/Music%20Website/ElyzaBleau-nightPortrait-1-lowRes_bwq0h2.png" alt="portrait of Elyza at night">
          <img src="https://res.cloudinary.com/dadqp2oia/image/upload/v1629671571/Music%20Website/ElyzaBleau-PortraitWhileFilming-lowRes_hi4hen.png" alt="portrait of Elyza while filming Pool of Grey">
          <img src="https://res.cloudinary.com/dadqp2oia/image/upload/v1629671571/Music%20Website/PoolOfGrey-portrait-night-lowRes_xsjdbz.png" alt="portrait of Elyza at night hair down">

        </div>
        <div class="column">
          <img src="https://res.cloudinary.com/dadqp2oia/image/upload/v1629671571/Music%20Website/Testify-openArms-lowRes_rqjpmu.png" alt="portrait of Elyza filming Testify open arms">
          <img src="https://res.cloudinary.com/dadqp2oia/image/upload/v1629671571/Music%20Website/Testify-lookingUp2-lowRes_ex9tse.png" alt="portrait of Elyza filming Testify looking at spinner">
          <img src="https://res.cloudinary.com/dadqp2oia/image/upload/v1629671571/Music%20Website/Testify-motel-lowRes_cbhq9e.png" alt="photo of motel and Elyza filming Testify">

        </div>
      </div>
</section>
