<mat-card class="mailing-card">
  <div class="row">


    <img
      class="logo"
      src="https://res.cloudinary.com/dadqp2oia/image/upload/v1629672279/Music%20Website/Logos/ElyzaBleau-Full-Logo_jatsxo.png"
      alt="Elyza Bleau logo"
    />
  </div>
  <div class="row">
    <mat-card-header>
      <mat-card-title>Wanna Join Elyza's Bungalow?</mat-card-title>
    </mat-card-header>
    <mat-card-actions>
      <button
        mat-button
        href="javascript:void(0)"
        onclick="ml_webform_4267936('show')"
      >
        Sign Up
      </button>
    </mat-card-actions>
  </div>

  <mail-dialog></mail-dialog>
</mat-card>
