import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bio-card-two',
  templateUrl: './bio-card-two.component.html',
  styleUrls: ['./bio-card-two.component.scss']
})
export class BioCardTwoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
