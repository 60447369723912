<section class="gallery jarallax">

    <div class="space"></div>

    <app-gallery-card></app-gallery-card>

    <div class="space"></div>

</section>

