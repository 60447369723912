<section class="epk jarallax">
  <div class="space"></div>

  <app-epk-header></app-epk-header>

  <app-epk-photo-card></app-epk-photo-card>

  <app-epk-bio-card></app-epk-bio-card>

  <app-epk-music-card></app-epk-music-card>

  <app-epk-video-card></app-epk-video-card>

  <app-epk-quotes-card></app-epk-quotes-card>

  <app-epk-photo-card-two></app-epk-photo-card-two>

  <app-epk-dl-card></app-epk-dl-card>

  <app-epk-logo-dl-card></app-epk-logo-dl-card>
</section>
