<mat-card class="watch-card">
    <mat-card-header>
      <mat-card-title>Watch Music Videos</mat-card-title>
    </mat-card-header>

    <img class="image" src="https://res.cloudinary.com/dadqp2oia/image/upload/v1629671570/Music%20Website/Apparition-filming2-lowRes_hxclqo.png" alt="color photo in a graffiti'd alley with Elyza playing guitar and singing while being filmed">

    <mat-card-actions>
      <a mat-button href="/videos">Watch</a>
    </mat-card-actions>
</mat-card>
