<div class="bio">

    
    <h5>Bundled up in my bungalow, cloudy skies and a harsh wind just outside my window, I opened an email from my uncle that shifted my entire direction.</h5>

        <h6><i>“The day before departure! Not very excited but when Roj came, rather left I supplied much needed salt water to this parched earth. He gave me a perfectly lovely green Kodak – ca-razy about it! Then he left and I wept and packed and went to bed and slept and woke up and left!”</i></h6>

        <h5>My grandma’s words, my Nana, were alive and well staring back at me from her diary written over 90 years ago in 1930. 21 and still in college for theatre, she led me into a story of travel by train across the United States, friends we’d never heard of, houses that I’d lived near in los angeles, oil rigs on the pacific coast, and family we never knew we had. The pandemic had just started back here in 2020, and I was hooked on a story from long ago.</h5>

</div>
